<template>
	<div class="py-5 mx-auto " style="max-width: 600px; height: 100vh;">
		<b-card>
			<h2>Screening Verification</h2>

			<div class="row">
				<div class="col-12 col-md-6 py-2 d-flex justify-content-start align-items-center">
					<span class="bg-light mr-2 p-2 rounded-circle d-flex align-items-center">
						<feather type="user" class="feather-sm"></feather>
					</span>
					<div>
						<h6 class="mb-1">Email</h6>
						<p class="mb-0">{{ bankInformation.bankEmail }}</p>
					</div>
				</div>
				<div class="col-12 col-sm-6 py-2 d-flex justify-content-start align-items-center">
					<span class="bg-light mr-2 p-2 rounded-circle d-flex align-items-center">
						<feather type="briefcase" class="feather-sm"></feather>
					</span>
					<div>
						<h6 class="mb-1">Bank</h6>
						<p class="mb-0">{{ bankInformation.bankDescription }}</p>
					</div>
				</div>
			</div>

			<b-form @submit.prevent="save" class="mt-4">
				<b-form-group label="Amount" label-for="amount">
					<b-form-input id="amount" required type="number" min="0" placeholder="Amount here"></b-form-input>
				</b-form-group>

				<b-button type="submit" variant="primary">Save</b-button>
			</b-form>
		</b-card>
	</div>
</template>

<script>
export default {
	data() {
		return {
			amount: 0,
			bankInformation: {},
		};
	},
	methods: {
		async save() {
			let data = {
				// Id, GUID, Amount, ModifiedBy
				Id: this.$route.query.id,
				GUID: this.$route.params.guid,
				Amount: this.amount,
				ModifiedBy: 1,
			};
			await this.$store.dispatch("screening/update_fromVerificationForm", data);
			this.amount = 0;
		},
		async getData() {
			let data = {
				id: this.$route.query.id,
				guid: this.$route.params.guid,
			};
			this.bankInformation = await this.$store.dispatch("screening/get_bankInformation", data);
		},
	},

	created() {
		this.getData();
	},
};
</script>
